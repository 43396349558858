import React from 'react';
import { Link } from 'gatsby';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getNormalizedBlogPostDescription } from '../../../util/formatter';
import { styled } from '@mui/system';

const Container = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: '1.5rem',
  paddingBottom: '2.6rem',
  height: '100%',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  transition: 'background-color 0.3s',
  padding: '0.2rem',
  borderRadius: '5px',
  color: theme.palette.primary.light,
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.background.light,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& p': {
    padding: '0.2rem',
    borderRadius: '5px',
    backgroundColor: 'none',
    transition: 'background-color 0.3s',
  },
  '& p:hover': {
    backgroundColor: theme.palette.background.light,
  },
}));

const StyledDescription = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.light,
}));

const RecentArticlePreview = ({ article }) => {
  const minutesToReadString = `${article.body.childMarkdownRemark.timeToRead} minute read`;

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Container>
        <Grid
          height="100%"
          item
          container
          direction="column"
          alignItems="space-between"
          xs={12}
        >
          <StyledLink
            aria-describedby={`Link to article ${article.name}`}
            to={`/blog/${article.slug}`}
          >
            <Typography variant="h6">{article.title}</Typography>
          </StyledLink>

          <StyledDescription
            aria-describedby={`Link to article ${article.name}`}
            to={`/blog/${article.slug}`}
          >
            <Description
              dangerouslySetInnerHTML={{
                __html: getNormalizedBlogPostDescription(
                  article.description.childMarkdownRemark.html,
                ),
              }}
              component="div"
              variant="body1"
            />
          </StyledDescription>
        </Grid>
        <Typography fontStyle={'italic'} align="left" variant="body2">
          {minutesToReadString} - {article.publishDate}
        </Typography>
      </Container>
    </Grid>
  );
};

export default RecentArticlePreview;
