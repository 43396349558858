import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { noSelect, terminalHeader } from '../../../styles';
import { styled } from '@mui/system';

const StyledTerminalHead = styled('div')({
  position: 'relative',
  ...terminalHeader,
});

const StyledCode = styled('code')(({ theme }) => ({
  color: theme.palette.secondary.accent,
  fontWeight: 600,
}));

const Terminal = ({ script, children }) => {
  return (
    <Grid
      container
      direction="column"
      sx={({ palette }) => ({
        p: 1.5,
        backgroundColor: palette.background.code,
        boxShadow: '0px 0px 16px -5px rgba(0,0,0,0.75)',
        borderRadius: '10px',
        ...noSelect,
      })}
    >
      <StyledTerminalHead />

      <Grid item container sx={{ pt: 2, pb: 2 }} gap={2} direction="column">
        {script && <StyledCode>~/blog > {script}</StyledCode>}
        {children}
        {script && <StyledCode>{'~/blog > _'}</StyledCode>}
      </Grid>
    </Grid>
  );
};

const Hero = ({ data }) => {
  const heroImage = getImage(data.heroImage.gatsbyImageData);

  return (
    <Terminal script={'./greeting.sh'}>
      <Grid
        alignItems="center"
        sx={{
          background: 'transparent',
          ...noSelect,
        }}
        container
        justifyContent={'center'}
        direction={'row'}
        spacing={2}
      >
        <Grid item xs={12} container justifyContent={'center'}>
          <GatsbyImage
            image={heroImage}
            alt={data.heroImage.description || ''}
            style={{
              width: '7rem',
              height: '7rem',
              display: 'block',
              borderRadius: '100px',
            }}
          />
        </Grid>
        <Grid
          item
          container
          alignContent={'center'}
          justifyContent={'center'}
          direction={'column'}
          xs={12}
        >
          <Typography fontWeight={200} component="p" noWrap variant="h4">
            Hi, I'm {data.name.split(' ')[0]}
          </Typography>

          <Typography
            sx={(theme) => ({ color: theme.palette.secondary.accent })}
            variant="h6"
          >
            {'< '}
            <Typography
              sx={(theme) => ({
                color: theme.palette.primary.light,
                fontStyle: 'italic',
                fontWeight: 'bold',
              })}
              variant="caption"
            >
              {data.shortBio.shortBio}
            </Typography>
            {' />'}
          </Typography>
        </Grid>
      </Grid>
    </Terminal>
  );
};

export default Hero;
