import React from 'react';
import RecentArticlePreview from '../recent-article-preview';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import LatestArticlePreview from '../latest-article-preview';
import { noSelect } from '../../../styles';

const ArticleList = ({ posts, justPreviews }) => {
  //latest article
  const post = [...posts]?.[0];

  return (
    <React.Fragment>
      {!justPreviews ? (
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} mb={'1.2rem'}>
            <Typography variant="h5" sx={noSelect}>
              Latest post
            </Typography>
            <Divider light />
          </Grid>
          <Grid container>
            <LatestArticlePreview article={post?.node} />
          </Grid>
          <Grid container alignItems="stretch" spacing={4}>
            <Grid item xs={12} mb={0}>
              <Typography variant="h6" sx={noSelect}>
                Recent
              </Typography>
              <Divider light />
            </Grid>
            {posts.slice(1, 6).map(({ node }) => (
              <RecentArticlePreview article={node} key={node.slug} />
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="stretch" spacing={4}>
          {posts.slice(0, 6).map(({ node }) => (
            <RecentArticlePreview article={node} key={node.slug} />
          ))}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ArticleList;
