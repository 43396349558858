import React from 'react';
import { graphql } from 'gatsby';
import Hero from '../components/Landing/hero';
import ApplicationContainer from '../components/application-container';
import ArticleList from '../components/Landing/article-list';
import ContentSection from '../components/content-section';
import { SEO as Seo } from '../components/seo';

export const Head = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Seo
      title={siteTitle}
      description={'Philip Englunds personal site and blog'}
      extraTags={[<meta property="og:type" content="website" />]}
    />
  );
};

const RootIndex = ({ data }) => {
  const posts = data.allContentfulBlogPost.edges;
  const [edge] = data.allContentfulPerson.edges;
  const author = edge.node;

  return (
    <>
      <ApplicationContainer>
        <Hero data={author} />
      </ApplicationContainer>

      <ApplicationContainer>
        <ContentSection>
          <ArticleList posts={posts} />
        </ContentSection>
      </ApplicationContainer>
    </>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          description {
            childMarkdownRemark {
              html
            }
          }
          body {
            childMarkdownRemark {
              html
              timeToRead
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
