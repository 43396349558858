import React from 'react';
import { Link } from 'gatsby';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  getNormalizedBlogPostDescription,
  getTimeToReadString,
} from '../../../util/formatter';
import { styled } from '@mui/system';

const Header = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  transition: 'background-color 0.3s',
  padding: '0.2rem',
  borderRadius: '5px',
  color: theme.palette.primary.light,
  fontWeight: 300,
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.background.paper,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  textDecoration: 'none !important',
  color: theme.palette.primary.main,
  transition: 'background-color 0.3s',
  padding: '0.2rem',
  borderRadius: '5px',
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const Highlighted = styled(Typography)(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 400,
  color: theme.palette.success.light,
  fontFamily: 'JetBrains Mono',
}));

const Tabbed = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  paddingLeft: '1.5rem',
  fontWeight: 400,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.light,
}));

const LatestArticlePreview = ({ article }) => {
  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="center"
        padding="1.4rem"
        mb={'1.4rem'}
        alignItems="flex-start"
      >
        <Grid container item xs={12} md={7}>
          <Header to={`/blog/${article.slug}`}>
            <Typography variant="h4">{article.title}</Typography>
          </Header>

          <Grid item xs={12} md={7}>
            <StyledLink to={`/blog/${article.slug}`}>
              <Description
                component="div"
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: getNormalizedBlogPostDescription(
                    article.description.childMarkdownRemark.html,
                    50,
                  ),
                }}
              />
            </StyledLink>
          </Grid>
        </Grid>
        <Grid fontWeight={400} item xs={12} md={5}>
          <Typography variant="h6" color="primary" component="h2">
            {' {'}
          </Typography>
          <Grid item xs={12}>
            <Tabbed variant="h6" component="h3">
              read_time:{' '}
              <Highlighted component="span">
                '
                {getTimeToReadString(
                  article.body.childMarkdownRemark.timeToRead,
                )}
                '
              </Highlighted>
              ,
            </Tabbed>
          </Grid>
          <Grid item xs={12}>
            <Tabbed variant="h6" component="h4">
              published:{' '}
              <Highlighted component="span">
                '{article.publishDate}'
              </Highlighted>
              ,
            </Tabbed>
          </Grid>
          <Grid item xs={12}>
            <Tabbed variant="h6" component="h5">
              link_to_post:{' '}
              <Link
                aria-describedby={'Link to latest post'}
                to={`/blog/${article.slug}`}
                style={{ textDecoration: 'none' }}
              >
                <Highlighted component="span">'[Read more]'</Highlighted>
              </Link>
              ,
            </Tabbed>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography color="primary" variant="h6">
              };
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LatestArticlePreview;
